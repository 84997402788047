import { objectToURL } from "./misc";

export const safeRoutes = {
  admin: {
    businesses: "/admin/businesses",
    home: "/admin",
    users: "/admin/users",
    businesses$idText: {
      home: (ownerId: string) => `/admin/businesses/${ownerId}/text`,
      queryBusinessName: (ownerId: string, businessPrimaryName: string) =>
        `/admin/businesses/${ownerId}/text?=${businessPrimaryName}`,
    },
  },
  api: {
    apiUtilsServer: "/api/api-utils/server",
    businessPlan: "/api/business-plan",
    financialProjections: "/api/financial-projections",
    lendicaCompnayDetails: "/api/lendica-company_details",
    primeWebhook: "/api/prime-webhook",
    veriffDecision: "/api/veriff-decision",
    veriffEvent: "/api/veriff-event",
    plaid: {
      accountsByInst: "/api/plaid/accounts/by-inst",
      assetReport: "/api/plaid/assetReport",
      createLinkToken: "/api/plaid/create-link-token",
      exchangePublicToken: "/api/plaid/exchange-public-token",
    },
    enigma: {
      updateEnigmaId: "/api/enigma/update-enigma-id",
    },
  },
  business: {
    add: "/business/add",
    assistant: "/business/assistant",
    businessPlanGenerator: "/business/programs/business-plan",
    funding: (slug?: string) => `/business/${slug}/lending`,
    hansaVerificationBadge: "/business/hansa-verification-badge",
    home: (slug?: string) => `/business/${slug}/home`,
    invite: "/business/invite",
    marketplace: "/business/marketplace",
    creditReports: {
      home: (slug?: string) => `/business/${slug}/credit-reports`,
      find: (slug?: string) => `/business/${slug}/credit-reports/find`,
      view: (slug?: string) => `/business/${slug}/credit-reports/view`,
      details: (slug?: string) => `/business/${slug}/credit-reports/details`,
    },
    profile: {
      home: "/business/profile",
      view: "/business/profile/view",
      edit: (businessTypeInfo: string) =>
        `/business/profile/edit/${businessTypeInfo}`,
    },
    manage: {
      account: {
        index: "/business/manage/account",
        edit: "/business/manage/account/edit",
      },

      index: "/business/manage",
      edit: "/business/manage/edit",
    },
    redeem: "/business/redeem",
    rewards: "/business/rewards",
    score: "/business/score",
    program: "/business/program",
    subscriptionManagement: "/business/subscription-management",
    verifyPhone: {
      home: "/business/verify-phone",
      text: "/business/verify-phone/text",
    },
    redeemPoints: {
      home: "/business/redeem",
      confirmationRedeemPoints: "/business/redeem/confirmation",
      enjoyRedeemedItem: "/business/redeem/enjoy",
    },
    learningCenter: {
      home: "/business/learning-center",
      resourceId: (resourceId: string) =>
        `/business/learning-center/${resourceId}`,
    },
    lending: {
      home: (slug?: string) => `/business/${slug}/lending`,
      lendflow: (slug?: string) => `/business/${slug}/lending/lendflow`,
      lendica: (slug?: string) => `/business/${slug}/lending/lendica`,
      prime: (slug?: string) => `/business/${slug}/lending/prime`,
    },
    localBenchmark: "/business/local-benchmark",
    programs: {
      checklist: "/business/programs/checklist",
      grants: "/business/programs/grants",
      businessPlan: (slug?: string) =>
        `/business/${slug}/programs/business-plan`,
      localBenchmark: "/business/programs/local-benchmark",
      home: "/business/programs",
      programSlug: (programSlug: string) => `/business/programs/${programSlug}`,
    },
    subscription: {
      home: "/business/subscription-management",
      originPageToSubscription: (subscriptionType: string) =>
        objectToURL("/business/subscription-management", {
          "subscribed-for": subscriptionType,
        }),
      subscriptionRedirectPayment: (
        baseURL: string,
        subscriptionType: string,
      ) =>
        objectToURL(baseURL, {
          "subscribed-for": subscriptionType,
        }),
      subscriptionWithProgramSlug: (
        baseURL: string,
        subscriptionType: string,
        programSlug: string,
      ) =>
        objectToURL(baseURL, {
          "subscribed-for": subscriptionType,
          "program-slug": programSlug,
        }),
      paymentStatus: "/business/subscription-management/payment-status",
      donation: "/business/donation-management",
    },
  },
  home: {
    index: "/",
  },
  login: "/login",
  logout: "/logout",
  signupSuccess: "/signup-success",
  auth: {
    index: "/auth",
    signUp: "/auth/signup",
    signUpVerify: "/auth/verify",
    signIn: "/auth/login",
    signOut: "/auth/logout",
    verifyEmailLink: "/auth/verify-link",
  },
  invites: {
    base: "/invite",
    purchase: (inviteSlug: string, code: string, email?: string) =>
      `/invite/${inviteSlug}/${code}/purchase${email ? `?email=${email}` : ""}`,
    success: (inviteSlug: string, code: string, email?: string) =>
      `/invite/${inviteSlug}/${code}/success${email ? `?email=${email}` : ""}`,
  },
};

export const getHostname = (request: Request): string => {
  const url = new URL(request.url);
  const hostname = `${url.protocol}//${url.host}`;
  return new URL(hostname).toString();
};
